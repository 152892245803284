import {
  Button,
  ProfileInfo,
  ToggleInput,
  toast,
} from '@addglowapp/components';
import {
  DndContext,
  DragEndEvent,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useState } from 'react';
import { LuHand } from 'react-icons/lu';
import {
  BrandFragment,
  CustomTraitEmbeddedSelectOptionsData,
  CustomTraitGroupEmbeddedTraitsData,
  CustomTraitGroupFragment,
  useCreateCustomTraitGroupMutation,
  useDeleteCustomTraitGroupMutation,
  useUpdateCustomTraitGroupMutation,
} from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';

import CreateGroupModal, {
  CreateCustomTraitGroupFormSchema,
} from './CreateGroupModal';
import CreateTraitModal, {
  CreateCustomTraitFormSchema,
} from './CreateTraitModal';

function SortableTraitItem({
  trait,
  group,
  onDeleteTrait,
  setEditTraitId,
  setSelectedTraitGroup,
}: {
  trait: CustomTraitGroupEmbeddedTraitsData;
  group: CustomTraitGroupFragment;
  onDeleteTrait: (groupId: string, traitId: string) => void;
  setEditTraitId: (id: string) => void;
  setSelectedTraitGroup: (group: CustomTraitGroupFragment) => void;
}): JSX.Element {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: trait.id ?? '' });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="relative my-2 rounded-md border p-2 pr-14"
    >
      <div className="flex flex-col items-center">
        <p className="opacity-50">{trait.title}</p>
        <div className="space-x-2">
          <Button
            color="transparent"
            onClick={() => {
              setEditTraitId(trait.id ?? '');
              setSelectedTraitGroup(group);
            }}
          >
            Edit
          </Button>
          <Button
            color="transparent"
            onClick={() => onDeleteTrait(group.id, trait.id ?? '')}
          >
            Delete
          </Button>
        </div>
      </div>
      {/* Drag handle on the right; full height */}
      <div
        {...attributes}
        {...listeners}
        className="absolute bottom-0 right-0 top-0 flex w-12 cursor-grab items-center justify-center bg-gray-100"
      >
        <LuHand />
      </div>
    </div>
  );
}

export function CustomTraitSection({
  refetch,
  brand,
}: {
  refetch: () => void;
  brand: BrandFragment;
}): JSX.Element {
  const [createGroup] = useCreateCustomTraitGroupMutation();
  const [updateGroup] = useUpdateCustomTraitGroupMutation();
  const [deleteGroup] = useDeleteCustomTraitGroupMutation();

  const [createGroupModalOpen, setCreateGroupModalOpen] = useState(false);
  const [selectedTraitGroup, setSelectedTraitGroup] =
    useState<CustomTraitGroupFragment | null>(null);

  const [editTraitGroup, setEditTraitGroup] =
    useState<CustomTraitGroupFragment | null>(null);

  const [editTraitId, setEditTraitId] = useState<string | null>(null);

  const sensors = useSensors(useSensor(PointerSensor));

  const onSubmitGroup = async (
    data: CreateCustomTraitGroupFormSchema,
    groupId?: string,
  ): Promise<void> => {
    if (groupId) {
      // Update existing group
      updateGroup({
        variables: {
          input: {
            id: groupId,
            data: {
              title: data.title,
            },
          },
        },
      })
        .then(() => {
          toast.success('Group updated!');
        })
        .catch((err) => {
          toast.error(
            logAndFormatError(err, 'Sorry, the group could not be updated.'),
          );
        })
        .finally(() => {
          setCreateGroupModalOpen(false);
          refetch();
        });
    } else {
      createGroup({
        variables: {
          input: {
            data: {
              brandId: brand.id,
              title: data.title,
            },
          },
        },
      })
        .then(() => {
          toast.success('Group created!');
        })
        .catch((err) => {
          toast.error(
            logAndFormatError(err, 'Sorry, the group could not be created.'),
          );
        })
        .finally(() => {
          setCreateGroupModalOpen(false);
          refetch();
        });
    }
  };

  const onDeleteGroup = (groupId: string): void => {
    if (!window.confirm(`Are you sure you want to delete this trait group?`)) {
      return;
    }
    deleteGroup({
      variables: {
        input: {
          id: groupId,
        },
      },
    })
      .then(() => {
        toast.success('Group deleted!');
      })
      .catch((err) => {
        toast.error(
          logAndFormatError(err, 'Sorry, the action could not be completed.'),
        );
      })
      .finally(() => {
        refetch();
      });
  };

  const handleToggleGroupEnabled = async (
    groupId: string,
    isEnabled: boolean,
  ): Promise<void> => {
    const group = brand.customTraitGroups.find((g) => g.id === groupId);
    if (!group) {
      toast.error('Trait group not found.');
      return;
    }

    if (group.traits.length === 0) {
      toast.error('Please add traits in order to enable this group');
      return;
    }

    try {
      await updateGroup({
        variables: {
          input: {
            id: groupId,
            data: {
              isEnabled,
            },
          },
        },
      });
      toast.success(
        `Group ${isEnabled ? 'enabled' : 'disabled'} successfully.`,
      );
      refetch();
    } catch (error) {
      toast.error(logAndFormatError(error, 'Failed to update group.'));
    }
  };

  const onSubmitTrait = async (
    data: CreateCustomTraitFormSchema,
    groupId: string,
    traitId?: string,
  ): Promise<void> => {
    const { options, type, title } = data;
    const group = brand.customTraitGroups.find((g) => g.id === groupId) ?? {
      traits: [],
    };

    const traits: CustomTraitGroupEmbeddedTraitsData[] = group.traits.map(
      ({ id, title, type, index, selectOptions }) => ({
        id,
        title,
        type,
        index,
        selectOptions: selectOptions.map(({ id, text, order }) => ({
          id,
          text,
          order,
        })),
      }),
    );

    // If the trait is SELECT, parse the new options and give them .order
    let selectOptions: CustomTraitEmbeddedSelectOptionsData[] = [];
    if (type === 'SELECT' && options) {
      selectOptions = options.split(',').map((optionText, i) => ({
        text: optionText.trim(),
        order: i + 1,
      }));
    }

    if (traitId) {
      // Update existing trait
      const idx = traits.findIndex((t) => t.id === traitId);
      if (idx === -1) return;
      traits[idx] = {
        ...traits[idx],
        title,
        ...(type === 'SELECT' ? { selectOptions } : {}),
      };
    } else {
      // Create a new trait
      const maxIndex = Math.max(0, ...traits.map((t) => t.index ?? 0));
      traits.push({
        title,
        type,
        index: maxIndex + 1, // so it appears last
        selectOptions,
      });
    }

    updateGroup({
      variables: {
        input: {
          id: groupId,
          data: {
            traits,
          },
        },
      },
    })
      .then(() => {
        toast.success('Profile traits updated successfully!');
      })
      .catch((err) => {
        toast.error(
          logAndFormatError(err, 'Sorry, the action could not be completed.'),
        );
      })
      .finally(() => {
        setSelectedTraitGroup(null);
        setEditTraitId(null);
        refetch();
      });
  };

  const onDeleteTrait = async (
    groupId: string,
    traitId: string,
  ): Promise<void> => {
    if (!window.confirm(`Are you sure you want to delete this trait?`)) {
      return;
    }
    const group = brand.customTraitGroups.find((g) => g.id === groupId);
    if (!group) {
      toast.error('Group not found.');
      return;
    }

    const updatedTraits = group.traits
      .map(({ id, title, type, index, selectOptions }) => ({
        id,
        title,
        type,
        index,
        selectOptions: selectOptions.map(({ id, text, order }) => ({
          id,
          text,
          order,
        })),
      }))
      .filter((trait) => trait.id !== traitId);

    try {
      await updateGroup({
        variables: {
          input: {
            id: groupId,
            data: {
              traits: updatedTraits,
            },
          },
        },
      });
      toast.success('Trait deleted successfully!');
      refetch();
    } catch (err) {
      toast.error(
        logAndFormatError(err, 'Sorry, the trait could not be deleted.'),
      );
    }
  };

  const handleDragEnd = async (
    event: DragEndEvent,
    groupId: string,
  ): Promise<void> => {
    const { active, over } = event;
    if (!over || active.id === over.id) {
      return;
    }

    const group = brand.customTraitGroups.find((g) => g.id === groupId);
    if (!group) return;

    const sortedTraits = [...group.traits].sort(
      (a, b) => (a.index ?? 0) - (b.index ?? 0),
    );

    const oldIndex = sortedTraits.findIndex((t) => t.id === active.id);
    const newIndex = sortedTraits.findIndex((t) => t.id === over.id);
    if (oldIndex === -1 || newIndex === -1) return;

    const reordered = arrayMove(sortedTraits, oldIndex, newIndex).map(
      (trait, i) => ({
        ...trait,
        index: i,
      }),
    );

    try {
      await updateGroup({
        variables: {
          input: {
            id: groupId,
            data: {
              traits: reordered.map((t) => ({
                id: t.id,
                title: t.title,
                type: t.type,
                index: t.index,
                selectOptions: t.selectOptions.map((opt) => ({
                  id: opt.id,
                  text: opt.text,
                  order: opt.order,
                })),
              })),
            },
          },
        },
      });
      toast.success('Traits reordered successfully!');
      refetch();
    } catch (err) {
      toast.error(
        logAndFormatError(err, 'Sorry, the traits could not be reordered.'),
      );
    }
  };

  return (
    <div className="space-y-4">
      {brand.customTraitGroups.map((group) => (
        <div className="space-y-4 border-b-2" key={group.id}>
          <div className="flex justify-between">
            <h2>{group.title}</h2>
            <div className="flex flex-row items-center justify-center space-x-2">
              <Button
                color="secondary"
                onClick={() => {
                  setEditTraitGroup(group);
                  setCreateGroupModalOpen(true);
                }}
              >
                Edit
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  onDeleteGroup(group.id);
                }}
              >
                Delete
              </Button>
              <ToggleInput.Labelled
                checked={group.isEnabled}
                label={group.isEnabled ? 'Disable' : 'Enable'}
                onChange={(checked) =>
                  handleToggleGroupEnabled(group.id, checked)
                }
              />
            </div>
          </div>

          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={(event) => handleDragEnd(event, group.id)}
          >
            <SortableContext
              items={[...group.traits]
                .sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
                .map((trait) => trait.id)}
              strategy={verticalListSortingStrategy}
            >
              {[...group.traits]
                .sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
                .map((trait) => (
                  <SortableTraitItem
                    key={trait.id}
                    trait={trait}
                    group={group}
                    onDeleteTrait={onDeleteTrait}
                    setEditTraitId={setEditTraitId}
                    setSelectedTraitGroup={setSelectedTraitGroup}
                  />
                ))}
            </SortableContext>
          </DndContext>

          {/* Button to add a new trait */}
          <ProfileInfo title="">
            <Button
              color="transparent"
              className="my-auto h-min underline"
              onClick={() => setSelectedTraitGroup(group)}
            >
              Add Trait
            </Button>
          </ProfileInfo>
        </div>
      ))}

      {/* Button to create a new group */}
      <Button
        onClick={() => {
          setEditTraitGroup(null);
          setCreateGroupModalOpen(true);
        }}
      >
        Add Custom Trait Group
      </Button>

      {/* Modal for creating/editing groups */}
      <CreateGroupModal
        isModalOpen={createGroupModalOpen}
        onClose={() => {
          setCreateGroupModalOpen(false);
        }}
        onSubmit={onSubmitGroup}
        group={editTraitGroup}
      />

      {/* Modal for creating/editing traits */}
      {selectedTraitGroup && (
        <CreateTraitModal
          isModalOpen={!!selectedTraitGroup}
          onClose={() => {
            setSelectedTraitGroup(null);
            setEditTraitId(null);
          }}
          group={selectedTraitGroup}
          onSubmit={onSubmitTrait}
          traitId={editTraitId}
        />
      )}
    </div>
  );
}
