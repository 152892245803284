import {
  Button,
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  ToggleInput,
  TextInputField,
} from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { useForm, useFieldArray, Control } from 'react-hook-form';
import { z } from 'zod';
import LayoutSection from 'src/components/LayoutSection';
import {
  BrandKlaviyoIntegrationFragment,
  CustomTraitGroupFragment,
  GetKlaviyoIntegrationDocument,
  KlaviyoProperty,
  useDeleteKlaviyoIntegrationMutation,
  useUpdateKlaviyoIntegrationMutation,
  useUpdateCustomTraitsMutation,
} from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';
import {
  KLAVIYO_PROPERTIES,
  KLAVIYO_PROPERTY_KEYS,
} from './constants/klaviyoProperties';
import { KlaviyoSubscribeListSection } from './KlaviyoSubscribeListSection';

interface MappingRow {
  addGlowProperty: KlaviyoProperty;
  isEnabled: boolean;
  destinationProperty: string;
}

const formSchema = z.object({
  brandKlaviyoMappings: z.array(
    z.object({
      addGlowProperty: z.enum(KLAVIYO_PROPERTY_KEYS),
      isEnabled: z.boolean(),
      destinationProperty: z.string(),
    }),
  ),
  customTraits: z.array(
    z.object({
      id: z.string(),
      title: z.string(),
      syncWithKlayvio: z.boolean(),
    }),
  ),
});

type FormValues = z.infer<typeof formSchema>;

/** Return the default mappings for built-in Klaviyo properties. */
function getDefaultMappings(
  klaviyoIntegration: BrandKlaviyoIntegrationFragment,
): MappingRow[] {
  return Object.keys(KLAVIYO_PROPERTIES).map((key) => {
    const propertyKey = key as KlaviyoProperty;
    const existing = klaviyoIntegration.brandKlaviyoMappings.find(
      (m) => m.addGlowProperty === propertyKey,
    );
    return (
      existing ?? {
        addGlowProperty: propertyKey,
        isEnabled: KLAVIYO_PROPERTIES[propertyKey].default ?? false,
        destinationProperty:
          KLAVIYO_PROPERTIES[propertyKey].label ?? propertyKey,
      }
    );
  });
}

/** Convert customTraitGroups into a flat array of trait objects. */
function flattenCustomTraits(customTraitGroups: CustomTraitGroupFragment[]): {
  id: string;
  title: string;
  syncWithKlayvio: boolean;
}[] {
  return customTraitGroups.flatMap((g) =>
    g.traits.map((t) => ({
      id: t.id,
      title: t.title,
      syncWithKlayvio: t.syncWithKlayvio,
    })),
  );
}

function DefaultTraitsSection({
  builtInRows,
  control,
}: {
  builtInRows: { id: string; addGlowProperty: string }[];
  control: Control<FormValues>;
}): JSX.Element {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Enable</TableHead>
          <TableHead>AddGlow Property</TableHead>
          <TableHead>Klaviyo Property</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {builtInRows.map((f, idx) => {
          const forcedEnabled =
            KLAVIYO_PROPERTIES[f.addGlowProperty as KlaviyoProperty]
              ?.forceEnabled;
          const label =
            KLAVIYO_PROPERTIES[f.addGlowProperty as KlaviyoProperty]?.label ??
            f.addGlowProperty;
          return (
            <TableRow key={f.id}>
              <TableCell>
                <ToggleInput.LabelledController
                  control={control}
                  name={`brandKlaviyoMappings.${idx}.isEnabled`}
                  disabled={Boolean(forcedEnabled)}
                />
              </TableCell>
              <TableCell>{label}</TableCell>
              <TableCell>
                {KLAVIYO_PROPERTIES[f.addGlowProperty as KlaviyoProperty]
                  ?.hasNoMapping ? null : (
                  <TextInputField.Controller
                    control={control}
                    name={`brandKlaviyoMappings.${idx}.destinationProperty`}
                  />
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

function CustomTraitsSection({
  traitFields,
  control,
}: {
  traitFields: { id: string; title: string }[];
  control: Control<FormValues>;
}): JSX.Element {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Enable</TableHead>
          <TableHead>Trait Title</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {traitFields.map((f, idx) => (
          <TableRow key={f.id}>
            <TableCell>
              <ToggleInput.LabelledController
                control={control}
                name={`customTraits.${idx}.syncWithKlayvio`}
              />
            </TableCell>
            <TableCell>{f.title}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export function KlaviyoSettings({
  className,
  klaviyoIntegration,
  customTraitGroups,
}: {
  className?: string;
  klaviyoIntegration: BrandKlaviyoIntegrationFragment;
  customTraitGroups: CustomTraitGroupFragment[];
}): JSX.Element {
  const [deleteKlaviyoIntegration, { loading: isDeleting }] =
    useDeleteKlaviyoIntegrationMutation({
      refetchQueries: [
        {
          query: GetKlaviyoIntegrationDocument,
          variables: { id: klaviyoIntegration.brandId },
        },
      ],
    });

  const [updateKlaviyoIntegration] = useUpdateKlaviyoIntegrationMutation();
  const [updateCustomTraits] = useUpdateCustomTraitsMutation();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isDirty },
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      brandKlaviyoMappings:
        klaviyoIntegration.brandKlaviyoMappings.length === 0
          ? getDefaultMappings(klaviyoIntegration)
          : klaviyoIntegration.brandKlaviyoMappings,
      customTraits: flattenCustomTraits(customTraitGroups),
    },
  });

  const { fields } = useFieldArray({ control, name: 'brandKlaviyoMappings' });
  const { fields: traitFields } = useFieldArray({
    control,
    name: 'customTraits',
  });

  /** Submit handler for the main form. */
  async function onSubmit(data: FormValues): Promise<void> {
    try {
      await updateKlaviyoIntegration({
        variables: {
          input: {
            id: klaviyoIntegration.brandId,
            data: { brandKlaviyoMappings: data.brandKlaviyoMappings },
          },
        },
      });
      await updateCustomTraits({
        variables: {
          input: {
            brandId: klaviyoIntegration.brandId,
            traits: data.customTraits.map((ct) => ({
              id: ct.id,
              data: { syncWithKlayvio: ct.syncWithKlayvio },
            })),
          },
        },
      });
      toast.success('Saved!');
    } catch (err) {
      toast.error(logAndFormatError(err));
    }
  }

  /** Toggle the built-in sync on/off. */
  function handleToggleSync(checked: boolean): void {
    if (checked) {
      setValue('brandKlaviyoMappings', getDefaultMappings(klaviyoIntegration), {
        shouldDirty: true,
      });
    } else {
      setValue('brandKlaviyoMappings', [], { shouldDirty: true });
    }
  }

  /** Disconnect the Klaviyo integration. */
  async function handleDeleteIntegration(): Promise<void> {
    if (window.confirm('Are you sure you want to disconnect Klaviyo?')) {
      try {
        await deleteKlaviyoIntegration({
          variables: { input: { id: klaviyoIntegration.brandId } },
        });
        toast.success('Klaviyo integration disconnected!');
      } catch (err) {
        toast.error(logAndFormatError(err));
      }
    }
  }

  // Filter out only the built-in rows (i.e. the default property keys).
  const builtInRows = fields.filter((f) =>
    KLAVIYO_PROPERTY_KEYS.includes(f.addGlowProperty),
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={clsx('space-y-8', className)}
    >
      <LayoutSection>
        <LayoutSection.Header
          title="Profile Synchronization"
          actions={
            <Button
              color="primary"
              type="submit"
              disabled={isSubmitting || !isDirty}
            >
              Save
            </Button>
          }
        />
        <LayoutSection.Body className="space-y-8">
          <div className="space-y-4">
            <ToggleInput.Labelled
              label="Enable Built-in Sync"
              checked={builtInRows.length > 0}
              onChange={handleToggleSync}
            />
            {builtInRows.length === 0 ? null : (
              <DefaultTraitsSection
                builtInRows={builtInRows}
                control={control}
              />
            )}
          </div>
          <div className="space-y-4">
            <p className="font-semibold">Custom Traits</p>
            <CustomTraitsSection traitFields={traitFields} control={control} />
          </div>
        </LayoutSection.Body>
      </LayoutSection>

      <KlaviyoSubscribeListSection klaviyoIntegration={klaviyoIntegration} />

      <LayoutSection>
        <LayoutSection.Header title="Klaviyo Connection" />
        <LayoutSection.Body className="space-y-4">
          <p>
            If you need to change or disconnect the integration, you can do so
            below:
          </p>
          <Button
            color="secondary"
            disabled={isDeleting}
            onClick={handleDeleteIntegration}
          >
            Disconnect Integration
          </Button>
        </LayoutSection.Body>
      </LayoutSection>
    </form>
  );
}
