import {
  ErrorableLoader,
  Card,
  TextInputField,
  Button,
  toast,
} from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import {
  GetUserByIdDocument,
  useCreateTrialBrandMutation,
} from 'src/generated/graphql';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useRequiredUserId } from 'src/hooks/useRequiredUserId';
import { logAndFormatError } from 'src/services/error-formatter';
import { getApolloErrorCode } from 'src/utils/apollo-error';

// keep in sync with backend
const createTrialBrandSchema = z.object({
  name: z.string().trim().min(4).max(20),
  subdomain: z
    .string()
    .min(4)
    .max(16)
    .regex(/^[a-z0-9A-Z-]+$/),
});

type FormData = z.infer<typeof createTrialBrandSchema>;

export function NewTrialPage(): JSX.Element {
  const [createTrialBrand] = useCreateTrialBrandMutation();

  const navigate = useNavigate();
  const userId = useRequiredUserId();
  const { user, error } = useCurrentUser();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      subdomain: '',
    },
    resolver: zodResolver(createTrialBrandSchema),
  });

  const onSubmit = async (data: FormData): Promise<void> => {
    try {
      const result = await createTrialBrand({
        variables: {
          input: {
            name: data.name,
            subdomain: data.subdomain,
          },
        },
        refetchQueries: [
          { query: GetUserByIdDocument, variables: { id: userId } },
        ],
        awaitRefetchQueries: true,
      });

      const newBrand = result.data?.createTrialBrand?.brand;
      if (newBrand) {
        toast.success('Brand created successfully');
        navigate(`/${newBrand.id}/customization`, {
          state: { newBrand: true },
        });
      } else {
        toast.error('Brand creation failed!');
      }
    } catch (err) {
      const apolloError = getApolloErrorCode(err, [
        'subdomain-in-use',
        'user-already-admin',
        'invalid-brand-name',
      ]);

      if (apolloError === 'subdomain-in-use') {
        toast.error('Subdomain already in use. Please choose another one.');
      } else if (apolloError === 'user-already-admin') {
        toast.error('You are already an admin for a brand.');
      } else if (apolloError === 'invalid-brand-name') {
        toast.error('Please choose another brand name/subdomain.');
      } else {
        toast.error(logAndFormatError(err));
      }
    }
  };

  const subdomain = useWatch({
    control,
    name: 'subdomain',
  });

  const isAdminUser =
    user?.brandAdminMemberships &&
    user.brandAdminMemberships.length > 0 &&
    !user.email.endsWith('@addglow.com');

  useEffect(() => {
    if (isAdminUser) {
      toast.error('You are already an admin for a brand.');
    }
  }, [isAdminUser]);

  if (!user) {
    return <ErrorableLoader error={error} />;
  }

  if (isAdminUser) {
    return <Navigate to="/" />;
  }

  return (
    <div className="flex h-full flex-1 items-center justify-center">
      <Card noMaxWidth padding className="w-full space-y-8 md:w-[600px]">
        <h1>AddGlow Signup</h1>
        <p>
          Please fill out the following form to set up your free community with
          AddGlow. You will be able to add more information later once you have
          signed up with us.
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <TextInputField.Controller
            control={control}
            name="name"
            label="Community Name"
            placeholder="My Brand Community"
          />
          <TextInputField.Controller
            control={control}
            className="flex-1"
            name="subdomain"
            label="Subdomain (lowercase letters, numbers, and dashes only)"
            placeholder="your-community"
          />
          <span className="text-sm text-gray-500">
            Your community will be accessible at{' '}
            <strong>https://{subdomain || 'your-community'}.addglow.app</strong>
          </span>
          <div className="flex justify-center">
            <Button
              size="large"
              className="w-full md:w-[240px]"
              type="submit"
              disabled={isSubmitting}
            >
              Sign Up
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
}
