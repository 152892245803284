import { Button, SelectField } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';
import LayoutSection from 'src/components/LayoutSection';
import {
  BrandFontOptionFragment,
  BrandFragment,
  useUpdateBrandMutation,
} from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';
import { fileSchema } from 'src/utils/validation';
import FileInput from '@src/components/FileInput';
import BrandPreview from './BrandPreview';
import HeaderPreview from './HeaderPreview';

interface Props {
  brand: BrandFragment;
  brandId: string;
  fontOptionsData: BrandFontOptionFragment[];
}

export const submitBrandingAssetsFormSchema = z.object({
  logoFile: fileSchema.nullish(),
  faviconFile: fileSchema.nullish(),
  welcomeModalLogoFile: fileSchema.nullish(),
  contentFontId: z.string().nullish(),
  headerBackgroundFile: fileSchema.nullish(),
});
export type SubmitBrandingAssetsFormData = z.infer<
  typeof submitBrandingAssetsFormSchema
>;

function BrandingAssetsSection({
  brand,
  brandId,
  fontOptionsData,
}: Props): JSX.Element {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, isDirty },
  } = useForm<SubmitBrandingAssetsFormData>({
    resolver: zodResolver(submitBrandingAssetsFormSchema),
    defaultValues: {
      ...brand,
    },
  });

  const [updateBrand] = useUpdateBrandMutation();
  const onSubmit = async (
    data: SubmitBrandingAssetsFormData,
  ): Promise<void> => {
    updateBrand({
      variables: {
        input: {
          id: brandId,
          data,
        },
      },
    })
      .then((result) => {
        const brandData = result.data?.updateBrand.brand;
        if (brandData) {
          reset(brandData);
        }
        toast.success('Brand data updated successfully.');
      })
      .catch((error) => {
        toast.error(
          logAndFormatError(error, 'Sorry, the action could not be completed.'),
        );
      });
  };

  const logoUrl = useWatch({ control, name: 'logoFile.hostedUrl' });
  const headerUrl = useWatch({
    control,
    name: 'headerBackgroundFile.hostedUrl',
  });

  const faviconUrl = useWatch({ control, name: 'faviconFile.hostedUrl' });

  const fontOptions = _.sortBy(
    fontOptionsData.map((option) => ({
      label: option.name,
      value: option.id,
    })),
    'label',
  );

  const contentFontId = useWatch({ control, name: 'contentFontId' });

  const contentFont = fontOptionsData.find(
    (option) => option.id === contentFontId,
  );

  return (
    <LayoutSection>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LayoutSection.Header
          title="Branding Assets"
          actions={
            <div className="flex space-x-2">
              <Button
                color="secondary"
                onClick={() => reset(brand)}
                disabled={isSubmitting || !isDirty}
              >
                Reset
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting || !isDirty}
              >
                Save
              </Button>
            </div>
          }
        />
        <LayoutSection.Body>
          <div className="flex space-x-8">
            <div className="flex-1 flex-col space-y-4">
              <FileInput.LabelledController
                category="brand-header-background"
                control={control}
                imagePreview
                name="headerBackgroundFile"
                label="Header Background(min 1500 x 110)"
              />
              <FileInput.LabelledController
                category="brand-logo"
                control={control}
                name="logoFile"
                imagePreview
                label="Company Logo (min 400 x 160)"
              />
              <FileInput.LabelledController
                category="brand-favicon"
                control={control}
                name="faviconFile"
                imagePreview
                accept={{
                  'image/png': [],
                }}
                label="Marketing Logo and Favicon (min 200 x 200)"
              />
              <FileInput.LabelledController
                category="welcome-modal-logo"
                control={control}
                name="welcomeModalLogoFile"
                imagePreview
                accept={{
                  'image/png': [],
                }}
                label="The logo to display on the welcome modal popup"
              />
              <SelectField.Controller
                control={control}
                name="contentFontId"
                label="Content Font"
                options={fontOptions}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                placeholder="Select a font"
              />
              <p className="text-sm text-gray-500">
                If you don&apos;t find the font you need, please contact{' '}
                <a
                  href="mailto:support@addglow.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  AddGlow
                </a>{' '}
                to have it added.
              </p>
            </div>
            <div className="flex-1">
              <BrandPreview colors={brand} contentFont={contentFont}>
                <HeaderPreview
                  brandFaviconUrl={faviconUrl}
                  backgroundImageUrl={headerUrl}
                  brandName={brand.name}
                  brandLogoUrl={logoUrl}
                  shopUrl={brand.shopUrl}
                  headerButtonIcon={brand.headerButtonIcon}
                  headerButtonText={brand.headerButtonText}
                />
              </BrandPreview>
            </div>
          </div>
        </LayoutSection.Body>
      </form>
    </LayoutSection>
  );
}

export default BrandingAssetsSection;
