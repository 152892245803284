import { Button, WelcomeCta } from '@addglowapp/components';
import {
  TextAreaInputField,
  TextInputField,
  ToggleInput,
} from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';
import LayoutSection from 'src/components/LayoutSection';
import { BrandFragment, useUpdateBrandMutation } from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';

interface Props {
  brand: BrandFragment;
  brandId: string;
}

export const welcomeModalFormSchema = z.object({
  welcomeCtaEnabled: z.boolean(),
  welcomeCtaTitle: z.string().max(250).nullish(),
  welcomeCtaBody: z.string().max(1000).nullish(),
  welcomeCtaDiscount: z.string().max(250).nullish(),
});
export type WelcomeModalFormData = z.infer<typeof welcomeModalFormSchema>;

function WelcomeBannerSection({ brand, brandId }: Props): JSX.Element {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, isDirty },
  } = useForm<WelcomeModalFormData>({
    resolver: zodResolver(welcomeModalFormSchema),
    defaultValues: brand,
  });

  const welcomeCtaTitle = useWatch({ control, name: 'welcomeCtaTitle' });
  const welcomeCtaBody = useWatch({ control, name: 'welcomeCtaBody' });

  const [updateBrand] = useUpdateBrandMutation();
  const onSubmit = async (data: WelcomeModalFormData): Promise<void> => {
    updateBrand({
      variables: {
        input: {
          id: brandId,
          data,
        },
      },
    })
      .then((val) => {
        const brandData = val.data?.updateBrand.brand;
        if (brandData) {
          reset(brandData);
        }
        toast.success('Brand data updated successfully.');
      })
      .catch((error) => {
        toast.error(
          logAndFormatError(error, 'Sorry, the action could not be completed.'),
        );
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LayoutSection>
        <LayoutSection.Header
          title="Welcome CTA"
          actions={
            <div className="flex space-x-2">
              <Button
                color="secondary"
                onClick={() => {
                  reset(brand);
                }}
                disabled={isSubmitting || !isDirty}
              >
                Reset
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting || !isDirty}
              >
                Save
              </Button>
            </div>
          }
        />
        <LayoutSection.Body>
          <div className="flex max-w-xl flex-col space-y-4">
            <ToggleInput.LabelledController
              name="welcomeCtaEnabled"
              label="Enable Welcome Popup CTA"
              control={control}
            />
            <p>Popup will only show to new logged out users.</p>
            <TextInputField.Controller
              control={control}
              className="flex-1"
              label="Title"
              name="welcomeCtaTitle"
            />
            <TextAreaInputField.Controller
              control={control}
              className="flex-1"
              label="Body"
              name="welcomeCtaBody"
            />
            <TextInputField.Controller
              control={control}
              className="flex-1"
              label="Providing a discount? Enter a shop discount code and it will be sent out in the welcome email."
              name="welcomeCtaDiscount"
            />
          </div>
          <p className="mt-8">Preview</p>

          <div className="w-3/5 rounded-md border p-8 pt-16">
            <WelcomeCta
              brandName={brand.name}
              imageUrl={
                brand.welcomeModalLogoFile?.hostedUrl ??
                brand.logoFile?.hostedUrl ??
                brand.faviconFile?.hostedUrl
              }
              welcomeCtaBody={welcomeCtaBody ?? ''}
              welcomeCtaTitle={welcomeCtaTitle ?? ''}
            />
          </div>
        </LayoutSection.Body>
      </LayoutSection>
    </form>
  );
}

export default WelcomeBannerSection;
